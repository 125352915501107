<template>
  <div id="pageNotFoundView">
    <SimpleContentBlock
      :simple-content-data="simpleContentData"
      :centered="true"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import SimpleContentBlock from '@/components/UI/SimpleContentBlock'

export default {
  name: 'PageNotFound',
  components: { SimpleContentBlock },
  data() {
    return {
      simpleContentData: {
        titleText: 'Page Not Found',
        contentText: '<p>The page you were looking for does not exist.</p>',
      },
    }
  },
  mounted() {
    this.setPageLoadingFalse()
  },
  methods: {
    ...mapMutations({
      setPageLoadingFalse: 'setPageLoadingFalse',
    }),
  },
}
</script>

<style lang="scss" scoped>
#pageNotFoundView {
  @apply py-36;
  min-height: 700px;
}
</style>
